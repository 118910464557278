@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply font-sans;
  font-family: 'Inter', 'roboto', 'DM Sans', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
